<template>
  <div class="governance" id="consumerprotection">
    <div class="ui grid container">
      <div class="intro-content">
        <h2>TayoCash Consumer Protection</h2>
        <div class="paragraph">
          <h4>Consumer Protection Framework</h4>
          <p>Consumer protection is an integral component of TayoCash’s corporate governance and risk management in order for it to sustain its commitment to high quality and inclusive financial services in an increasingly digital society. Over the past few years, digital financial services have rapidly grown and have started to move towards financial inclusion. The need for effective consumer protection is greater than ever.</p>

          <p>TayoCash believes that an increased awareness of consumer protection rights, greater transparency, fairness, and swiftness in handling consumer complaints are opportunities  to strengthen the trust of customers and the relationship with them in the long-term. To truly protect our customers, especially those that are vulnerable and inexperienced in financial technology, TayoCash has developed a <span class="vtxt-align">Consumer Protection Risk Management System</span> that establishes responsibility, controls authority, measures, and accountability of the supervisory authorities that are in charge of carrying out the digital financial services that we offer. It incorporates policies, processes, and measurement and control procedures to identify, measure, monitor, mitigate, and manage consumer protection risks relevant in TayoCash’s operation. This further enhances the customer experience and the overall trust in digital financial services.</p>
        </div>

        <div class="paragraph">
          <h4>Consumer Protection Standards of Conduct</h4>
          <p>TayoCash’s policies on consumer protection are guided by five (5) Standards of Conduct:</p>

          <p><span class="v-bold">1. Disclosure and Transparency</span>
            Having such standards of conduct entails disclosure of information by TayoCash, including its privacy policy, terms and conditions, fees and charges, product key information, merchant dashboard, complaints handling and settlement, and many more.
          </p>
          <p>
            TayoCash also guarantees that all product and service information shall be readily available to the public. This collection of information can be found in the TayoCash App and website. Consumers will be properly informed once there are updates to the information for proper product and service education.
          </p>
          <p><span class="v-bold">2. Protection of Client Information</span>
            TayoCash upholds the security and confidentiality of its customers’ personal information as defined and specified in its <a class="link" href="/privacypolicy">Privacy Policy</a>. It also ensures that all information provided by the customer is verified in accordance with its Know-Your-Customer (KYC) process and the applicable laws and regulations.
          </p>
          <p><span class="v-bold">3. Fair Treatment</span>
            TayoCash’s <a class="link" href="/termsandconditions">Terms and Conditions</a> provide a clear understanding of how business will be conducted and how it protects our customers. The organization guarantees consumer policies that are fair and reasonable, and in accordance with industry practices and the rules and regulations of the BSP and applicable laws.
          </p>

          <p><span class="v-bold">4. Effective Recourse</span>
            TayoCash aims to provide customers with accessible and adequate support every time concerns, updates, complaints, and disputes arise. Customers can make their requests known to TayoCash through its various support channels such as the <a class="link" href="/helpcenter">Help Center</a> in the TayoCash App or website, and Service Hotline number at <span class="vtxt-align">(02) 8888-TAYO</span>.
          </p>

          <p>TayoCash has designed a Consumer Assistance Management System (CAMS) that will acknowledge all incoming concerns from its support channels, automatically create tickets, and inform customers of their ticket progress. This will ensure that the relevant business units are on top of all customer concerns and that material complaints are reported and addressed promptly.</p>

          <p>Through its Customer Care Analytics System, TayoCash is likewise able to consolidate and identify the frequency and nature of concerns and complaints to drive improvement in the delivery of its products and services. This will also monitor, audit, and assess the effectiveness and efficiency of the TayoCash customer support through its ticket response time and other relevant metrics. In addition to this, TayoCash invests in continuing training and development programs that focus on best practices in call handling, techniques in communication and listening, proper documentation, problem-solving and troubleshooting skills, conflict negotiation, and appropriate responses to varying customer behaviors.</p>

          <p><span class="v-bold">5. Financial Education and Awareness</span>
            TayoCash is committed to educating its consumers regarding the different financial products, services, and opportunities across all TayoCash platforms, channels, and customer interactions. This will enable them to make well-informed financial decisions, taking advantage of the various options available to them.
          </p>
        </div>

        <div class="buttons">
          <a href="/erm" class="yellow">ENTERPRISE RISK MANAGEMENT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'consumerprotection'
}
</script>

<style scoped lang='scss'></style>
